import React from "react";
import {Link} from "react-router-dom";

const Explore = () => {
    return (
        <section className="bg-white dark:bg-gray-900 mx-16">
            <div className="flex justify-between">
                <div>
                <p className="text-2xl sm:text-left sm:text-4xl font-lufgaBold font-bold">
                    Choose a Home
                    </p>

                </div>
                <div className="flex items-center">
                    <Link to='https://account.promittoltd.com/' className="text-sm sm:text-right sm:text-xl text-gray-500 hover:text-[#F2B807]">Explore All<i class="ri-arrow-right-line ml-2"></i></Link>

                </div>

            </div>


        </section>
    );
}

export default Explore;